<template>
  <div class="background">
    <div class="container-open-page">
      <div class="card-open-page">
        <div class="logo">
          <v-logo class="margin-right-15"/>
          <v-logo-carteiro-digital class="margin-top-5" height="28"/>
        </div>
        <div class="content">
          <div class="message">O Carteiro Digital está aberto em outra aba. Clique no botão <b>Usar aqui</b> para continuar nesta aba.</div>
          <div class="footer">
            <v-button size="small" class="button" title="Usar aqui" type="submit" value="Usar aqui" @click.native="certainWindow()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VButton from "@/components/forms/VButton";
import VLogo from "../../../components/logos/VLogo";
import VLogoCarteiroDigital from "../../../components/logos/VLogoCarteiroDigital";
import WebsocketService from "@/support/services/websocket";
import {mapGetters} from "vuex";

export default {
  name: 'OpenPage',

  components: {
    VLogoCarteiroDigital,
    VLogo,
    VButton
  },

  computed: {
    ...mapGetters({
      userGetter: "auth/user",
    }),
  },

  methods: {
    certainWindow() {
      WebsocketService.socket().emit('set-session-tab', this.userGetter.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.background {
  user-select: none;
  background-image: url("~whiteLabel/images/home-background.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
  overflow: hidden;

  .logo {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .container-open-page {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .card-open-page {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 17px 50px 0 rgba(0, 0, 0, .19), 0 12px 15px 0 rgba(0, 0, 0, .24);
      padding: 30px 45px;
      width: 500px;

      .content {
        .message {
          font-size: 15px;
          color: #3c4858;
          margin-bottom: 20px;
        }
        .footer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .button {
            margin-top: 15px;
            width: fit-content;
          }

        }
      }




    }
  }
}

</style>